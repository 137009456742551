body {
  margin: 0;
}

.logo {
  float: left;
  width: 32px;
  height: 32px;
  margin: 16px 24px 16px 0;
  background: url("../public/logo-32.png");
  background-size: 32px 32px;
  background-repeat: no-repeat;
}

@media screen and (max-width: 767px) {
  .logo {
    background: url("../public/logo-32.png");
    background-repeat: no-repeat;
    float: left;
    width: 32px;
    height: 32px;
    margin: 16px 24px 16px 0;
    background-size: 32px 32px;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 767px) {
  .landing-intro img {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .landing-intro {
    width: 100%;
    text-align: center;
  }
}

.public-menu {
  float: left;
  font-weight: bold;
}

.public-menu .ant-space-item a {
  color: #ffffff;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

@media screen and (max-width: 576px) {
  .ant-layout-sider-trigger {
    display: none;
  }
}
.ant-public-layout {
  background: #ffffff !important;
}
.ant-layout {
  background: #f0f2f5;
}
.ant-layout-footer {
  background: #f0f2f5 !important;
}

.ant-layout-header {
  padding-inline: 24px !important;
}

.auth-container {
  background: #f0f2f5;
  min-height: calc(100vh - 144px);
}

.public-container {
  background: #ffffff;
  min-height: calc(100vh - 278px);
}

.auth-container-body {
  background: linear-gradient(#fff 0 0) padding-box,
    linear-gradient(to bottom, #1890ff, #1890ff) border-box;
  border: 3px solid transparent;
  padding-left: 75px;
  padding-right: 75px;
  padding-top: 30px;
  border-radius: 5px;
}

.language-selector img {
  padding-right: 5px;
}

.ant-select-item-option-content img {
  padding-right: 5px;
}

.login-space .ant-space-item:nth-child(2) {
  flex: auto;
}

.protected-header-space .ant-space-item:hover {
  background-color: #252a3d;
}

.protected-header-space .ant-space-item {
  padding-left: 12px;
  padding-right: 12px;
}

.protected-header-space .ant-space-item .ant-space-item {
  padding-left: 0px;
  padding-right: 0px;
}

.error-layout {
  flex: auto;
  min-height: calc(100vh);
  position: relative;
}

.landing-item-wrapper-row {
  height: 360px;
}

@media screen and (max-width: 767px) {
  .landing-item-wrapper-row {
    height: 600px;
  }
}

.landing-item-row {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}

.landing-item-col-left {
  padding: 0 32px;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .landing-item-col-left {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
}

.landing-item-col-left-title {
  font-size: 32px;
  font-weight: 400;
  color: #404040;
  margin-top: 120px;
  position: relative !important;
}

@media screen and (max-width: 767px) {
  .landing-item-col-left-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}

.landing-item-col-left-text {
  margin-top: 20px;
  position: relative !important;
}

@media screen and (max-width: 767px) {
  .landing-item-col-left-text {
    width: 100%;
    top: auto;
  }
}

.landing-item-col-right {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .landing-item-col-right {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
}

.landing-item-col-right span {
  display: block;
  width: 250px;
}

@media screen and (max-width: 767px) {
  .landing-item-col-right span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
}

.landing-item-col-right-image {
  display: block;
}

.features-row {
  position: relative;
  max-width: 1200px;
  margin: auto;
  will-change: transform;
}

@media screen and (max-width: 767px) {
  .features-row {
    margin: 20px auto;
    height: auto;
  }
}

.features-item-col {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}

.features-item-col span {
  display: inline-block;
  vertical-align: top;
}

.features-item-icon {
  width: 100%;
  font-size: 48px;
}

.features-item-icon-wrapper {
  width: 15%;
}

.features-item-content {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}

.public-footer-wrapper {
  overflow: hidden;
  position: relative;
  min-height: 360px;
}

.public-footer-row {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
  padding: 64px 24px 80px;
}

.public-footer-col {
  padding: 0 32px;
}

.public-footer-logo {
  max-width: 112px;

  width: 112px;
  height: 32px;
  background: url("../public/logo-32-dark.png");
  background-size: 112px 32px;
  background-repeat: no-repeat;
}

.public-footer-col-header {
  margin-bottom: 24px;
}

.public-footer-col-link {
  margin-bottom: 12px;
  float: left;
  clear: both;
}

.plans-wrapper {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
  padding: 128px 24px;
}

.plans-content-wrapper {
  min-height: 400px;
}

.plans-col {
  margin-bottom: 24px;
}

.plans-box {
  width: 260px;
  border-radius: 4px;
  background: #eef0f3;
  text-align: center;
  color: #666;
  min-height: 400px;
  margin: auto;
  border: 1px solid transparent;
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  transform: translateY(0);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
    box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.plans-box-active {
  border-color: #2f54eb;
}

.plans-box-top {
  width: 100%;
  padding: 16px 24px;
}

.plans-box-top-active {
  background-color: #2f54eb;
  color: #ffffff;
}

.plans-box-top-active h3,
.plans-box-top-active h5 {
  color: #ffffff;
}

.plans-box-body {
  line-height: 2;
  font-weight: 300;
  margin: 32px 24px 48px;
}

.plans-box-divider {
  display: block;
  height: 1px;
  background: #d9d9d9;
  margin: 0 24px;
}

.plans-box-bottom {
  margin: 18px 24px;
}

.plans-box-bottom button {
  padding: 0 24px;
}

.faq-wrapper {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
  padding: 128px 24px;
}

.business-card .ant-card-head {
  text-align: center;
}

.page-header-row {
  background-color: #ffffff;
  margin-bottom: 30px;
  border-radius: 2px;
  border: 1px solid #f0f0f0;
  padding-top: 20px;
  padding-bottom: 20px;
}

.cover-image-upload .ant-upload-select {
  width: 100%;
}

.cover-image-upload .ant-image {
  width: 100%;
}

.cover-image-upload .ant-image:after {
  content: "\A";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  cursor: pointer;
}

.cover-image-upload .ant-image:hover:after {
  opacity: 1;
}

.profile-image-upload .ant-avatar:after {
  content: "\A";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  cursor: pointer;
}

.profile-image-upload .ant-avatar:hover:after {
  opacity: 1;
}

.profile-meta .ant-card-meta-avatar {
  padding-inline-end: 80px;
}

.add-card-section {
  margin-bottom: 16px;
}

.cover-photo-upload .ant-upload {
  overflow: hidden;
  width: 100% !important;
}

.profile-photo-upload .ant-upload {
  width: 152px !important;
  height: 152px !important;
  margin-inline-end: 0px !important;
}

.ant-select-selection-item .ant-avatar {
  margin-bottom: 3px;
}

.profile-image-preview-container {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #fff;
  margin-bottom: 64px;
}

.profile-image-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview-area-card .ant-card-head {
  padding-left: 0px;
}

@media screen and (min-width: 992px) {
  .preview-area-right {
    text-align: right;
  }
}

.preview-card .ant-card-body {
  background-color: #ffffff;
}

.add-button-wrapper {
  margin-bottom: 16px;
  text-align: right;
}

@media (max-width: 768px) {
  .add-button-wrapper {
    text-align: left;
  }
}

.next-prev-buttons {
  width: 100%;
}

.next-prev-buttons .ant-space-item {
  width: 100%;
}

.company-admin-page {
  background-color: #ffffff;
  padding: 16px;
}

.filter-area {
  background: #ffffff;
}

.admin-page h4 {
  margin-top: 0.5em;
}

.admin-page .ant-tabs .ant-tabs-nav {
  margin-bottom: 0px !important;
}

.admin-page
  .ant-descriptions
  .ant-descriptions-row
  .ant-descriptions-item-content {
  background-color: #ffffff;
}

.admin-page .ant-descriptions {
  margin-bottom: 20px;
}

.form-area {
  background-color: #ffffff;
  padding: 8px 32px 16px 32px;
}
